import baseUrl from "../config/baseUrl";

const dashboardPendingRequests = async ({ token, id, is_admin }) => {
  try {
    const response = await fetch(`${baseUrl}/api/get-pending-request`, {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    return error;
  }
};

export default dashboardPendingRequests;

/*
allUnassigned: 29
code: 200
email_count: 13
fax_count: 6
getAssigned: 6
message: 
"Document Counts"
myAssigned: 6
scanned_count: 10
*/
