// PROD
// const baseUrl = "https://dms.nablasol.net";

// DEV
//const baseUrl = "https://lvdmsup.nablasol.net";

const baseUrl = "https://nbldocs.nablasol.net";

// const baseUrl = "https://admindms.consumerlaw.com/public";

export default baseUrl;
